















import { Component, PropSync, Vue } from 'vue-property-decorator'

import {
  CustomerDetailsStepContext
} from '../../organisms/CustomerDetailsStep/CustomerDetailsStep.config'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component({
  name: 'HeadingSwitch'
})
export class HeadingSwitch extends Vue {
  @PropSync('mode', { type: String, required: true })
  public _mode!: string

  public switchMode (): void {
    switch (this._mode) {
      case CustomerDetailsStepContext.Login:
        this._mode = CustomerDetailsStepContext.Register
        break
      case CustomerDetailsStepContext.Register:
        this._mode = CustomerDetailsStepContext.Login
        break
      // Other modes...
    }
  }
}

export default HeadingSwitch
