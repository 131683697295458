import { VueConstructor } from 'vue'

import {
  ContextSwitchComponent,
  FormContextSwitchContracts
} from '../../molecules/FormContextSwitch/FormContextSwitch.contracts'
import HeadingSwitch from '../../molecules/FormContextSwitch/HeadingSwitch.vue'
import RadioSwitch from '../../molecules/FormContextSwitch/RadioSwitch.vue'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const BUYER_CONTEXT_SWITCH_COMPONENT_KEY = 'BuyerContextSwitchComponent'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface BuyerContextSwitchConfig {
  acceptPrivacyForLoggedInUser: boolean
  loginRegisterContextComponent: string
  loginRegisterContextProps: FormContextSwitchContracts
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const defaultConfig: BuyerContextSwitchConfig = {
  acceptPrivacyForLoggedInUser: false,
  loginRegisterContextComponent: ContextSwitchComponent.RadioSwitch,
  loginRegisterContextProps: {
    radio: [
      {
        name: 'type',
        label: 'login',
        value: 'login',
        id: 'type-0'
      },
      {
        name: 'type',
        label: 'register',
        value: 'register',
        id: 'type-1'
      }
    ]
  }
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const loginRegisterContextComponentsRegistry: Record<string, VueConstructor> = {
  [ContextSwitchComponent.HeadingSwitch]: HeadingSwitch,
  [ContextSwitchComponent.RadioSwitch]: RadioSwitch
}
