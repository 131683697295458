










import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { FormContextSwitchContracts } from './FormContextSwitch.contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component({
  name: 'RadioSwitch'
})
export class RadioSwitch extends Vue {
  @PropSync('mode', { type: String, required: true })
  public readonly _mode!: string

  @Prop({ type: Object, required: true, default: null })
  public readonly contexts!: FormContextSwitchContracts

  public get radioProps (): FormContextSwitchContracts['radio'] | undefined {
    if (!this.contexts || !Object.prototype.hasOwnProperty.call(this.contexts, 'radio')) {
      return
    }
    return this.contexts.radio
  }
}

export default RadioSwitch
