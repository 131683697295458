import { NavTabsItemProps } from '../../../../dsl/molecules/NavTabs'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface FormContextSwitchContracts {
  radio?: Array<ContextRadioProps>
  tabs?: ContextTabProps
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface ContextRadioProps {
  class?: string[]
  id: string
  label: string
  name: string
  value: string
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface ContextTabProps {
  active: string
  tabs: Array<NavTabsItemProps>
  isWrapped: boolean
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export enum ContextSwitchComponent {
  HeadingSwitch = 'headingSwitch',
  RadioSwitch = 'radioSwitch',
  TabsSwitch = 'tabsSwitch'
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export enum TabsSwitchTab {
  ShipOnBilling = 'shipOnBilling',
  ShipOnOther = 'shipOnOther'
}
